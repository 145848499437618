import { Injectable } from '@angular/core';
import htmlToImage from 'html-to-image';
import download from 'downloadjs';
import jsPDF from 'jspdf';
import jsPDFAPI from 'jspdf';
import { of, Observable } from 'rxjs';
import { PracticalWorkService } from './practical-work.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {

  constructor(
    private practicalWorkService: PracticalWorkService,
    private http: HttpClient
  ) { }

  get(id = this.practicalWorkService.getId()) {
    const url = `${environment.apiUrl}/PracticalWork/${id}/PracticalWorkFile?stateid=${this.practicalWorkService.stateId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
      }), responseType: 'blob'
    });
  }

  getByStage(stageId) {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/PracticalWorkFile?stateid=${this.practicalWorkService.stateId}&stageId=${stageId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
      }), responseType: 'blob'
    });
  }


}
