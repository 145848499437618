import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { InterviewFile } from '../../../pages/interview/interview.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaderDeleteComponent } from './file-uploader-delete/file-uploader-delete.component';
import { InterviewService } from '../../../pages/interview/interview.service';
import { PracticalWorkService } from '../../services/practical-work.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  showError = false;
  isTeacher = false;

  @ViewChild('fileInput', { static: true }) fileInput;

  @Input() interviewFile = new InterviewFile();
  @Input() interview = null;
  @Input() index;
  @Output() addFile = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private interviewService: InterviewService,
    private practicalWorkService: PracticalWorkService
  ) { }

  ngOnInit() {
    this.isTeacher = this.practicalWorkService.isTeacher();
  }

  openFileUploader() {
    if (this.interviewFile.name) {
      if (this.interviewFile.id > 0) {
        this.interviewService.getFile(this.interviewFile).subscribe((file) => {
          this.downloadFile(file);
        })
      } else {
        this.downloadFile(this.interviewFile);
      }

      return;
    }
    if (!this.isTeacher) {
      this.fileInput.nativeElement.click();
    }
  }

  private downloadFile(file) {
    const base64str = file.fileBase64;
    const fileName = file.name;

    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: 'application/pdf' });
    importedSaveAs(blob, fileName);
  }

  selectFile($event) {

    const element = $event.srcElement;
    if (!element.files || element.files.length === 0) {
      return;
    }

    const file = element.files[0];

    if (!this.correctFileExtension(file.name)) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.interviewFile.name = file.name;
    this.interviewFile.fileExtension = file.name;
    this.interviewFile.fileExtension = this.generateExtension(file.name);
    this.interviewFile.interviewId = this.interview.id;
    this.interviewFile.fileBase64 = this.getBase64($event.srcElement.files[0]);
  }

  private correctFileExtension(name) {
    const extension = this.generateExtension(name).toLowerCase();
    return extension === '.word' || extension === '.pdf' ? true : false;
  }

  private generateExtension(name) {
    let split = name.split('.')[1];
    if (split === 'docx' || split === 'doc') {
      split = 'word';
    }
    return `.${split}`;
  }

  private getBase64(file): any {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Data = (reader.result as string).split(',')[1].toString();
      this.interviewFile.fileBase64 = base64Data;
      this.addFile.emit(this.interviewFile);
    };
    reader.onerror = (error) => {
    };
  }

  deleteFile($event, file) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const modalRef = this.modalService
      .open(FileUploaderDeleteComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-delete animated bounceInUp' });
    modalRef.componentInstance.file = file;
    modalRef.componentInstance.deleteFile.subscribe((file) => {
      const object = { file, index: this.index };
      this.delete.emit(object);
    });

  }


}
