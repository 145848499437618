import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostitComponent } from './components/postit/postit.component';
import { EditComponent } from './components/postit/edit/edit.component';
import { ChangeColorComponent } from './components/change-color/change-color.component';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { DxTextBoxModule, DxValidationGroupModule, DxValidatorModule, DxTextAreaModule } from 'devextreme-angular';
import { DeleteComponent } from './components/postit/delete/delete.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MoreInfoComponent } from './components/more-info/more-info.component';
import { CapturePageComponent } from './components/capture-page/capture-page.component';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploaderDeleteComponent } from './components/file-uploader/file-uploader-delete/file-uploader-delete.component';
import { HeaderPrintComponent } from './components/header-print/header-print.component';
import { ErrorPdfComponent } from './components/error-pdf/error-pdf.component';
import { FileUploaderPostitComponent } from './components/postit/file-uploader/file-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DndModule,
    RouterModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxValidatorModule,
    DxTextAreaModule,
    DragDropModule
  ],
  declarations: [
    PostitComponent,
    EditComponent,
    ChangeColorComponent,
    HeaderComponent,
    DeleteComponent,
    MoreInfoComponent,
    CapturePageComponent,
    SaveButtonComponent,
    FileUploaderComponent,
    FileUploaderDeleteComponent,
    HeaderPrintComponent,
    ErrorPdfComponent,
    FileUploaderPostitComponent
  ],
  entryComponents: [
    EditComponent,
    DeleteComponent,
    FileUploaderDeleteComponent,
    ErrorPdfComponent
  ],
  exports: [
    PostitComponent,
    EditComponent,
    ChangeColorComponent,
    HeaderComponent,
    MoreInfoComponent,
    CapturePageComponent,
    SaveButtonComponent,
    FileUploaderComponent,
    FileUploaderDeleteComponent,
    HeaderPrintComponent,
    ErrorPdfComponent
  ]
})
export class SharedModule { }
