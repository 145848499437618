import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BrainstormingAvailable } from './shared/guards/brainstorming.guard';
import { IdeasAvailable } from './shared/guards/ideas.guard';
import { CanvasAvailable } from './shared/guards/canvas.guard';
import { CircleAvailable } from './shared/guards/circle.guard';
import { InterviewAvailable } from './shared/guards/interview.guard';
import { CircleFinalAvailable } from './shared/guards/circle-final.guard';
import { CashFlowAvailable } from './shared/guards/cashflow.guard';
import { CanvasFinalAvailable } from './shared/guards/canvas-final.guard';
import { HomeGuard } from './pages/home/home.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    loadChildren: './pages/pages.module#PagesModule',
    canActivate: [ HomeGuard ]
  },
  {
    path: 'brainstorming',
    loadChildren: './pages/brainstorming/brainstorming.module#BrainstormingModule',
    canActivate: [ BrainstormingAvailable ]
  },
  {
    path: 'ideas',
    loadChildren: './pages/ideas/ideas.module#IdeasModule',
    canActivate: [ IdeasAvailable ]
  },
  {
    path: 'canvas',
    loadChildren: './pages/canvas/canvas.module#CanvasModule',
    canActivate: [ CanvasAvailable ]
  },
  {
    path: 'customer-propositions',
    loadChildren: './pages/customer-propositions/customer-propositions.module#CustomerPropositionsModule',
    canActivate: [ CircleAvailable ]
  },
  {
    path: 'interview',
    loadChildren: './pages/interview/interview.module#InterviewModule',
    canActivate: [ InterviewAvailable ]
  },
  {
    path: 'customer-propositions-final',
    loadChildren: './pages/customer-propositions-final/customer-propositions.module#CustomerPropositionsFinalModule',
    canActivate: [ CircleFinalAvailable ]
  },
  {
    path: 'canvas-final',
    loadChildren: './pages/canvas-final/canvas.module#CanvasFinalModule',
    canActivate: [ CanvasFinalAvailable ]
  },
  {
    path: 'cashflow',
    loadChildren: './pages/cash-flow/cash-flow.module#CashFlowModule',
    canActivate: [ CashFlowAvailable ]
  },
  {
    path: 'list-student',
    loadChildren: './pages/list-student/list-student.module#ListStudentModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
