import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PracticalWorkService } from '../../services/practical-work.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent implements OnInit {

  @Input() title = '¿Qué debo Hacer?';
  @Input() description = null;
  @Input() display = 'none';
  @Input() infoIcon = './assets/info.svg';

  @Output() close = new EventEmitter();

  isTeacher = false;

  constructor(
    private practicalWorkService: PracticalWorkService
  ) { }

  ngOnInit() {
    this.isTeacher = this.practicalWorkService.isTeacher();
    if (this.isTeacher) {
      this.display = 'none';
    }
  }

  closeInfo() {
    this.display = 'none';
    this.close.emit();
  }

}
