export class PostitFile {
  id: number;
  name: string;
  fileExtension: string;
  fileBase64: string;

  constructor() {
    this.id = 0;
    this.name = null;
    this.fileExtension = null;
    this.fileBase64 = null;
  }

}
