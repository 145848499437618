import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { STAGES } from '../constants/stages.constans';
import { MenuService } from '../services/menu.service';

@Injectable({
  providedIn: 'root'
})
export class AvailableStepGuard implements CanActivate {

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {

  }

  step = STAGES.BRAINSTORMING;

  canActivate() {
    const step = this.menuService.getStage(this.step);
    if (step && step.active) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
