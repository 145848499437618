import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PostitService } from '../postit.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  @Output() deletePostit: EventEmitter<any> = new EventEmitter<boolean>();
  @Input() postit: null;

  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private postitService: PostitService
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  confirm() {
    this.loading = true;
    this.postitService.delete(this.postit)
      .subscribe((ok) => {
        this.loading = false;
        this.closeModal();
        this.deletePostit.emit(true);
      });
  }

}
