import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InterviewService } from 'src/app/pages/interview/interview.service';

@Component({
  selector: 'app-file-uploader-delete',
  templateUrl: './file-uploader-delete.component.html',
  styleUrls: ['./file-uploader-delete.component.scss']
})
export class FileUploaderDeleteComponent implements OnInit {

  @Output() deleteFile: EventEmitter<any> = new EventEmitter<boolean>();
  @Input() file;

  loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private interviewService: InterviewService,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  confirm() {
    this.loading = true;
    const cloneFile = Object.assign({}, this.file);
    if (this.file.id > 0) {
      this.interviewService.deleteFile(this.file)
        .subscribe((ok) => {
          this.loading = false;
          this.closeModal();
          this.deleteFile.emit(cloneFile);
        });
    } else {
      this.loading = false;
      this.closeModal();
      this.deleteFile.emit(cloneFile);
    }

  }

}
