import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuService } from './shared/services/menu.service';
import { PracticalWorkService } from './shared/services/practical-work.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EnrollmentsEnum } from './models/canvas/enrollments.enum.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private menuService: MenuService,
    private practicalWorkService: PracticalWorkService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    //const studentId = 'b38f9d48c2307b8b3aa4f7b606ce776eeb5ab45b';
    //const studentId = '420112ef4af012991a0dbddbe961702970472f8d';
    //const studentId = '71b4a806c0fb917e79e7cbab0b72bd8ec1305358' // PRofesor
    const studentId = this.getParameterByName('stateid');
    this.practicalWorkService.stateId = studentId;
    this.practicalWorkService.InitializePracticalWork(studentId).subscribe((result) => {
      this.practicalWorkService.isReady.emit();
      if (this.practicalWorkService.isTeacher()) {
        this.router.navigate(['/list-student']);
      } else {
        this.practicalWorkService.getByStudentAndCourseIds().subscribe(() => {
          this.menuService.getMenu().subscribe((menu: any) => this.menuService.menu = menu);
        });
      }
    });
  }

  ngOnInit() {
    window.onbeforeunload = () => {
      console.log('borrado')
      this.practicalWorkService.resetLocalStorage();
    };
  }


  private getParameterByName(name: any) {
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].split('/')[0].replace('/+/g', ' '));
  }

}
