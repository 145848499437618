import { Injectable, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { PracticalWorkStage, Stage } from '../../pages/home/home.models';
import { HttpClient } from '@angular/common/http';
import { PRACTICAL_WORK_ID } from '../../config/practical_work';
import { PracticalWorkService } from './practical-work.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnInit {

  public menu: Stage[] = [];

  constructor(
    private http: HttpClient,
    private practicalWorkService: PracticalWorkService
  ) {
  }

  ngOnInit() {
  }

  getMenu() {
    const url = `${environment.apiUrl}/practicalWork/${this.practicalWorkService.getId()}/stage`;
    return this.http.get(url)
      .pipe(
        map(((result: any) => result.stages)),
        first()
      );
  }

  enableStep(id, value = true) {
    const stageFound = this.menu.filter(stage => stage.id === id);
    if ( stageFound.length > 0 ) {
      stageFound[0].active = value;
    }
  }

  getStage(id) {
    let stage;
    const stageFound = this.menu.filter(stage => stage.id === id);
    if ( stageFound.length > 0 ) {
      stage = stageFound[0];
    }

    return stage;
  }
}
