import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PracticalWorkService } from '../../../services/practical-work.service';
import { PostitFile } from './file-uploader.models';

@Component({
  selector: 'app-postit-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderPostitComponent implements OnInit, OnChanges {
  showError = false;
  isTeacher = false;

  @ViewChild('fileInput', { static: true }) fileInput;

  @Input() file: any;
  @Input() interview = null;
  @Input() placeholder = 'Adjuntar Archivo PowerPoint o Pdf';
  @Input() index;
  @Input() allowFiles;
  @Input() type = 'CONVERSATION';
  @Input() errorMessage = 'El archivo debe ser Powerpoint o Pdf';
  @Output() addFile = new EventEmitter();
  @Output() openFile = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @Output() delete = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private practicalWorkService: PracticalWorkService
  ) { }

  ngOnInit() {
    this.isTeacher = this.practicalWorkService.isTeacher();
  }

  ngOnChanges(changes: any) {
    this.file = changes.file.currentValue ? changes.file.currentValue : new PostitFile();
  }

  openFileUploader() {
    if (this.file && this.file.name) {
      this.openFile.emit(this.file);
    } else {
      if (!this.isTeacher) {
        this.fileInput.nativeElement.click();
      }
    }
  }

  selectFile($event) {
    if (!this.file) {
      this.file = new PostitFile();
    }
    const element = $event.srcElement;
    if (!element.files || element.files.length === 0) {
      return;
    }

    const file = element.files[0];

    if (!this.correctFileExtension(file.name)) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.file.name = file.name;
    this.file.fileExtension = this.generateExtension(file.name);
    this.file.fileBase64 = this.getBase64($event.srcElement.files[0]);
  }

  private correctFileExtension(name) {
    const extension = this.generateExtension(name).toLowerCase();
    return extension === '.word' || extension === '.pdf' ? true : false;
  }

  private generateExtension(name) {
    let split = name.split('.')[1];
    if (split === 'docx' || split === 'doc') {
      split = 'word';
    } else if (split === 'xlsx' || split === 'xls') {
      split = 'excel';
    } else if (split === 'ppt') {
      split = 'powerpoint';
    }
    return `.${split}`;
  }

  private getBase64(file): any {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Data = (reader.result as string).split(',')[1].toString();
      this.file.fileBase64 = base64Data;
      this.addFile.emit(this.file);
    };
    reader.onerror = (error) => {
    };
  }

  deleteFile($event, file) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.removeFile.emit(true);

  }
}
