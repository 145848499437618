import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PracticalWorkService } from '../../shared/services/practical-work.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {

  constructor(
    private router: Router,
    private practicalWorkService: PracticalWorkService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.practicalWorkService.isReady.subscribe(() => {
      const isTeacher = this.practicalWorkService.isTeacher();
      if ( isTeacher && !this.practicalWorkService.initialStateTeacherSession ) {
        this.router.navigate(['/list-student']);
        return;
      }
      return true;
    })
    return true;
  }

}
