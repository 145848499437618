import { Injectable, OnDestroy, OnInit, HostListener, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OauthState } from 'src/app/models/canvas/oauthstate.model';
import { User } from 'src/app/models/canvas/user.model';
import { Course } from 'src/app/models/canvas/course.model';
import { environment } from 'src/environments/environment';
import { EnrollmentsEnum } from 'src/app/models/canvas/enrollments.enum.model';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PracticalWorkService implements OnInit, OnDestroy {

  private user: User;
  private course: Course;
  practicalWork = [];
  stateId;
  isReady = new EventEmitter();

  selectedPostit: any;

  initialStateTeacherSession = false;

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.resetLocalStorage();
  }

  ngOnDestroy() {
    this.resetLocalStorage();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.resetLocalStorage();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.resetLocalStorage();
  }

  InitializePracticalWork(stateId: string) {
    const url = `${environment.apiUrl}/CanvasState/${stateId}`;
    return this.http.get<OauthState>(url)
      .pipe(
        map(((result: OauthState) => {
          this.saveUserOnLocalStorage(result.user);
          this.saveCourseOnLocalStorage(result.course);
          return result;
        }))
      );
  }

  getById(id) {
    const url = `${environment.apiUrl}/PracticalWork/${id}`;
    return this.http.get(url)
      .pipe(
        map(((result: any) => {
          localStorage.setItem('PRACTICAL_WORK_ID', result.id);
          const response = result.practicalWorkStages;
          this.practicalWork = response;
          return result.practicalWorkStages;
        }))
      );
  }

  getByStudentAndCourseIds() {
    const url = `${environment.apiUrl}/PracticalWork?idStudent=${this.user.id}&idCourse=${this.course.id}`;
    return this.http.get(url)
      .pipe(
        map(((result: any) => {
          localStorage.setItem('PRACTICAL_WORK_ID', result.id);
          const response = result.practicalWorkStages;
          this.practicalWork = response;
          return result.practicalWorkStages;
        }))
      );
  }

  getPracticalWorkStageId(stageId) {
    let id = null;

    const found = this.practicalWork.filter(practicalWork => practicalWork.stageId === stageId);
    if (found.length > 0) {
      id = found[0].id;
    }

    return id;
  }

  getPracticalWorkStage(stageId) {
    let stage = null;

    const found = this.practicalWork.filter(practicalWork => practicalWork.stageId === stageId);
    if (found.length > 0) {
      stage = found[0];
    }

    return stage;
  }

  getPracticalWorkStageHasPdf(stageId) {
    let hasFile = false;

    const found = this.practicalWork.filter(practicalWork => practicalWork.stageId === stageId);
    if (found.length > 0) {
      hasFile = found[0].hasFile;
    }

    return hasFile;
  }

  updatePdfStep(practicalWorkStageId, file) {
    const url = `${environment.apiUrl}/PracticalWorkStage/${practicalWorkStageId}`;
    return this.http.put(url, { file });
  }

  getId() {
    return localStorage.getItem('PRACTICAL_WORK_ID');
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('CANVAS_USER'));
  }

  getCourse(): Course {
    return JSON.parse(localStorage.getItem('CANVAS_USER'));
  }

  isTeacher() {
    let teacher = false;
    const user: User = this.getUser();
    if (user && user.enrollment === EnrollmentsEnum.TeacherEnrollment) {
      teacher = true;
    }

    return teacher;
  }

  private saveUserOnLocalStorage(user: User): void {
    this.user = user;
    if (user) {
      localStorage.setItem('CANVAS_USER', JSON.stringify(this.user));
    }
    else {
      localStorage.setItem('CANVAS_USER', null);
    }
  }

  private saveCourseOnLocalStorage(course: Course): void {
    this.course = course;
    if (course) {
      localStorage.setItem('CANVAS_COURSE', JSON.stringify(this.course));
    } else {
      localStorage.setItem('CANVAS_COURSE', null);
    }
  }

  resetLocalStorage(): void {
    localStorage.setItem('CANVAS_USER', null);
    localStorage.setItem('CANVAS_COURSE', null);
    localStorage.setItem('PRACTICAL_WORK_ID', null);
  }

  loadEventos(ref: HTMLElement){
    const moveEvent = fromEvent(window, 'mousemove')
      .pipe(
        map((e: MouseEvent) => ({ x: e.clientX, y: e.clientY })),
        map(pos => this.calculatePosition(pos.x, pos.y, ref, 'move'))
      );

    const dragEvent = fromEvent(window, 'drag')
      .pipe(
        map((e: MouseEvent) => ({ x: e.clientX, y: e.clientY })),
        map(pos => this.calculatePosition(pos.x, pos.y, ref, 'drag'))
      );

    return [moveEvent, dragEvent];
  }

  private calculateDistance(ref: HTMLElement){
    const clientRect: any = ref.getBoundingClientRect();
    const half = clientRect.width / 2
    const calc = Math.sqrt(half * 4);
    return calc - 5.5;
  }

  private calculatePosition(posX: number, posY: number, ref: HTMLElement, type: string){
    let postitWidth = 55;
    let postitHeight = 35;

    const clientRect: any = ref.getBoundingClientRect();
    const centerX = clientRect.x + ( clientRect.width / 2 ); 
    const centerY = clientRect.y + ( clientRect.height / 2 ); 
    const distance = Math.sqrt(Math.abs(centerX - posX) * 2 + Math.abs(centerY - posY) * 2);
    const maxDistance = this.calculateDistance(ref);

    if (this.selectedPostit) {
      const postit = this.selectedPostit.getBoundingClientRect();
      postitWidth = postit.width / 2;
      postitHeight = postit.height / 2;
    }

    const left = posX - postitWidth < clientRect.x
    const right = posX + postitWidth > clientRect.x + clientRect.width
    const top = posY - postitHeight < clientRect.y 
    const bottom = posY + postitHeight > clientRect.y + clientRect.height
    const boundries = !left && !right && !top && !bottom;
    
    return distance < maxDistance && boundries;
  }
}
