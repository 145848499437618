import { Injectable } from '@angular/core';
import { STAGES } from '../constants/stages.constans';
import { AvailableStepGuard } from './available-step.guard';
import { MenuService } from '../services/menu.service';

@Injectable({
  providedIn: 'root'
})
export class CanvasAvailable extends AvailableStepGuard {

  step = STAGES.CANVAS;

}
