import { PRACTICAL_WORK_ID } from '../../config/practical_work';
import { PracticalWorkService } from '../../shared/services/practical-work.service';

export class InterviewFile {
  id: number;
  name: string;
  fileExtension: string;
  fileBase64: string;
  interviewId: number;

  constructor() {
    this.id = 0;
  }
}

export class InterviewState {
  id: number;
  name: string;
}

export class Interview {
  id: number;
  practicalWorkId: number;
  title: string;
  description: string;
  hypothesis: string;
  indicator: string;
  successCriterio: string;
  hypothesisValidation: boolean;
  interviewStateId: number;
  interviewState: InterviewState;
  interviewFiles: [InterviewFile];
  active = false;

  constructor(
    practicalWorkId
  ) {
    this.id = 0;
    this.practicalWorkId = Number(practicalWorkId);
    this.hypothesisValidation = false;
    this.interviewFiles = [new InterviewFile()];
    this.active = false;
  }
}

