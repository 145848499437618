import { Component, OnInit, Input, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import htmlToImage from 'html-to-image';
import download from 'downloadjs';
import jsPDF from 'jspdf';
import jsPDFAPI from 'jspdf';
import { PracticalWorkService } from '../../services/practical-work.service';
import { CapturePageService } from './capture-page.service';
import { GeneratePdfService } from '../../services/generate-pdf.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorPdfComponent } from '../error-pdf/error-pdf.component';

@Component({
  selector: 'app-capture-page',
  templateUrl: './capture-page.component.html',
  styleUrls: ['./capture-page.component.scss']
})
export class CapturePageComponent implements OnInit {

  @Input() elementRef: [] = [];
  @Input() fileName: any = null;
  @Input() stage: any = null;

  first = false;
  generatingCapture = false;
  elementsToPng = [];
  showMoreInfo = false;
  availableShowMoreInfo = false;
  interval = null;

  constructor(
    private renderer: Renderer2,
    private practicalWorkService: PracticalWorkService,
    private capturePageService: CapturePageService,
    private generatePdfService: GeneratePdfService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  /*   generateImage() {
      this.generatingCapture = true;
      this.elementRef.forEach((el: any) => {
        const elementRef = el.nativeElement ? el.nativeElement : el;
        let elementRefClone = elementRef;
        this.renderer.removeClass(elementRefClone, 'display-none');
        htmlToImage.toPng(elementRefClone)
          .then((dataUrl) => {
            this.elementsToPng.push({ elementRefClone, dataUrl });
            if (this.isLastFile()) {
              this.generatePdf();
            }
          })
          .catch((error) => {
            this.generatingCapture = false;
            this.elementsToPng = [];
          });
      });
    } */

  /* private generateImage() {
    const pdf = new jsPDF('l', 'mm', 'a4', true);

    this.elementsToPng.forEach((png, index) => {
      const width = png.elementRefClone.clientWidth;
      const pdfWidth = pdf.internal.pageSize.width;
      const pdfHeight = (png.elementRefClone.clientHeight * pdfWidth) / width;

      pdf.addImage(png.dataUrl, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
      if ((index + 1) !== this.elementsToPng.length) {
        pdf.addPage();
      }
    });

    pdf.save(`${this.fileName}.pdf`);
    const base64 = pdf.output('datauri').split(',')[1].toString();
    const practicalWorkStageId = this.practicalWorkService.getPracticalWorkStageId(this.stage);
    this.practicalWorkService.updatePdfStep(practicalWorkStageId, base64).subscribe();

    this.generatingCapture = false;
    this.first = false;
    this.availableShowMoreInfo = false;
    this.showMoreInfo = false;
    this.elementsToPng = [];
  }

  private isLastFile() {
    return this.elementsToPng.length === this.elementRef.length;
  }
 */
  generateImage() {
    this.generatingCapture = true;
    this.generatePdfService.getByStage(this.stage).subscribe(data => {
      importedSaveAs(data, this.fileName);
      this.generatingCapture = false;
    }, () => {
      this.generatingCapture = false;
      const modalRef = this.modalService
        .open(ErrorPdfComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-delete animated bounceInUp' });
    });
  }

}
