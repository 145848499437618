import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Stage } from '../../../pages/home/home.models';
import { post } from 'selenium-webdriver/http';
import { PracticalWorkService } from '../../services/practical-work.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostitService {
  WIDTH = 110;
  HEIGHT = 70;

  constructor(
    private http: HttpClient,
    private practicalWorkService: PracticalWorkService
  ) { }

  delete(postit) {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Postit/${postit.postItId}`;
    return this.http.delete(url);
  }

  create(postit, stageId) {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Postit/`;

    const data = {
      stageId,
      containerId: postit.containerId,
      postit
    }

    return this.http.post(url, data);
  }

  update(postit) {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Postit/${postit.postItId}`;
    const data = {
      containerId: postit.containerId,
      postit
    }
    return this.http.put(url, data)
  }
}