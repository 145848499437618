import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteComponent } from '../delete/delete.component';
import { PostitService } from '../postit.service';
import { PracticalWorkService } from '../../../services/practical-work.service';
import { PostitFile } from '../file-uploader/file-uploader.models';
import { FileUploaderService } from '../file-uploader/file-uploader.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  @Input() postit = null;
  @Input() withOutTag = false;
  @Output() saveEvent = new EventEmitter();
  @Output() deletePostit = new EventEmitter();

  @ViewChild('tagInputEl', { static: true }) tagInputEl: ElementRef;

  postitClone = null;
  showCreateTag = false;
  loading = false;
  isTeacher = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private postitService: PostitService,
    private practicalWorkService: PracticalWorkService,
    private fileUploaderService: FileUploaderService
  ) { }

  ngOnInit() {
    this.postitClone = Object.assign({}, this.postit);
    if (this.postitClone.files.length === 0) {
      const emptyFile = new PostitFile();
      this.postitClone.files.push(emptyFile);
    }
    this.isTeacher = this.practicalWorkService.isTeacher();
  }

  closeModal() {
    this.resetFilePostit();
    this.activeModal.close();
  }

  save() {
    this.resetFilePostit();
    this.loading = true;
    this.postitService.update(this.postitClone).subscribe((ok) => {
      this.loading = false;
      this.closeModal();
      this.saveEvent.emit(this.postitClone);
    }, () => {
      this.loading = false;
      this.closeModal();
      this.saveEvent.emit(this.postitClone);
    });
  }

  private resetFilePostit() {
    if (this.postitClone.files.length === 1 && !this.postitClone.files[0].name) {
      this.postitClone.files = [];
    }
  }

  createTag($event) {
    if (this.isTeacher) {
      return;
    }
    this.showCreateTag = true;
    setTimeout(() => this.tagInputEl.nativeElement.focus(), 20)
  }

  tagInputFocusOut($event) {
    if (this.tagInputEl.nativeElement.textContent) {
      return;
    }

    this.showCreateTag = false;
  }

  delete() {
    this.closeModal();
    const modalRef = this.modalService.open(DeleteComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-delete animated bounceInUp' });
    modalRef.componentInstance.postit = this.postit;
    modalRef.componentInstance.deletePostit.subscribe(() => {
      this.deletePostit.emit(this.postit);
    });
  }

  openFile() {
    if (this.postitClone.files[0].name) {
      if (this.postitClone.files[0].id === 0) {
        this.fileUploaderService.downloadFile(this.postitClone.files[0]);
      } else {
        this.fileUploaderService.get(this.postitClone.files[0].id)
          .subscribe((data: any) => {
            this.fileUploaderService.downloadFile(data);
          });
      }
      return;
    }
  }

  removeFile() {
    this.postitClone.files = [];
  }

  addFile(file) {
    if (this.postitClone.files.length === 0) {
      this.postitClone.files[0] = file;
    }
  }

}
