import { Component, OnInit } from '@angular/core';
import { PracticalWorkService } from '../../services/practical-work.service';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnInit {

  saveButtonLoading = false;
  isTeacher = false;

  constructor(
    private practicalWorkService: PracticalWorkService
  ) { }

  ngOnInit() {
    this.isTeacher = this.practicalWorkService.isTeacher();
  }

  save() {
    this.saveButtonLoading = true;
    setTimeout(() => {
      this.saveButtonLoading = false;
    }, 1000);
  }

}
