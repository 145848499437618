import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from 'src/app/config/api_url';
import { map } from 'rxjs/operators';
import { Interview } from './interview.models';
import { environment } from 'src/environments/environment';
import { PracticalWorkService } from '../../shared/services/practical-work.service';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  interviews: Interview[] = [];
  emptyList = new EventEmitter();

  constructor(
    private http: HttpClient,
    private practicalWorkService: PracticalWorkService
  ) { }

  get() {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Interview`;
    return this.http.get(url)
      .pipe(
        map((response: any) => response.interview)
      );
  }

  save(interviewData) {
    const interview = Object.assign({}, interviewData);
    delete interview.interviewFiles;
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Interview`;
    const data = {
      interview
    };
    return this.http.post(url, data);
  }

  delete(interview) {
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Interview/${interview.id}`;
    return this.http.delete(url);
  }

  update(interviewData) {
    const interview = Object.assign({}, interviewData);
    delete interview.interviewFiles;
    const url = `${environment.apiUrl}/PracticalWork/${this.practicalWorkService.getId()}/Interview/${interview.id}`;
    const data = {
      interview
    };
    return this.http.put(url, data);
  }

  addFile(interviewFile) {
    const url = `${environment.apiUrl}/InterviewFile`;
    const data = {
      interviewFile
    }
    return this.http.post(url, data);
  }

  deleteFile(file) {
    const url = `${environment.apiUrl}/InterviewFile/${file.id}`;
    return this.http.delete(url);
  }

  getFile(file) {
    const url = `${environment.apiUrl}/InterviewFile/${file.id}`;
    return this.http.get(url)
      .pipe(
        map((result: any) => result.file )
      )
  }
}
