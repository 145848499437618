import { Component, OnInit, HostListener, Input, ViewChild, ElementRef, Output, EventEmitter, Renderer2, HostBinding } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditComponent } from './edit/edit.component';
import { DeleteComponent } from './delete/delete.component';
import { PostitService } from './postit.service';
import { STAGES } from '../../constants/stages.constans';
import { PracticalWorkService } from '../../services/practical-work.service';

@Component({
  selector: 'app-postit',
  templateUrl: './postit.component.html',
  styleUrls: ['./postit.component.scss']
})
export class PostitComponent implements OnInit {
  isSingleClick: Boolean = true;
  @HostBinding('class.z-index-99999') showColors = false;

  showCreateTag = false;
  isTeacher = false;

  @Input() postit: any;
  @Input() container: any;
  @Input() focused = true;
  @Input() index: any;
  @Input() stage: any;
  @Input() isBrainstorming = false;
  @Output() deletePostit = new EventEmitter();
  @Output() focusOutTextArea = new EventEmitter();

  @ViewChild('textArea', { static: true }) textArea: ElementRef;
  @ViewChild('tagInputEl', { static: true }) tagInputEl: ElementRef;


  constructor(
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private postitService: PostitService,
    private practicalWorkService: PracticalWorkService
  ) {

  }

  ngOnInit() {
    if (this.postit.title === '' && this.focused) {
      this.textArea.nativeElement.focus();
    }
    this.isTeacher = this.practicalWorkService.isTeacher();
  }

  preventDefault($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
  }

  edit($event, post) {
    this.preventDefault($event);
    const modalRef = this.modalService
      .open(EditComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-edit animated bounceInUp' });
    modalRef.componentInstance.postit = this.postit;
    modalRef.componentInstance.withOutTag = !this.isBrainstorming;
    modalRef.componentInstance.saveEvent.subscribe((postit) => {
      this.postit = postit;
    });
    modalRef.componentInstance.deletePostit.subscribe(() => {
      this.deletePostit.emit(this.postit);
    });
  }

  delete($event, post) {
    if (!this.postit.postItId) {
      return;
    }

    this.preventDefault($event);
    const modalRef = this.modalService
      .open(DeleteComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-delete animated bounceInUp' });
    modalRef.componentInstance.postit = this.postit;
    modalRef.componentInstance.deletePostit.subscribe(() => {
      this.deletePostit.emit(this.postit);
    });
  }

  clickTextArea($event) {
    this.preventDefault($event);
  }

  clickToChangeColor($event) {
    this.preventDefault($event);
    this.showColors = true;
  }

  hideChangeColor() {
    this.showColors = false;
  }

  focusOut() {
    if (this.postit.title !== '') {
      this.postitService.create(this.postit, this.stage)
        .subscribe((response: any) => {
          this.postit.postItId = response.postitId || response.id;
        });
    }
  }

  createTag($event) {
    this.preventDefault($event);
    this.showCreateTag = true;
    setTimeout(() => this.tagInputEl.nativeElement.focus(), 20)
  }

  clickTagInput($event) {
    this.preventDefault($event);
  }

  tagInputFocusOut($event) {
    if (this.tagInputEl.nativeElement.textContent) {
      return;
    }

    this.showCreateTag = false;
    this.postitService.update(this.postit).subscribe();
  }
}
