import { Injectable } from '@angular/core';
import { ListStudent } from '../../pages/list-student/list-student.models';

@Injectable({
  providedIn: 'root'
})
export class StudentListService {

  student: ListStudent;

  constructor() {}
}
