import { Injectable } from '@angular/core';
import { STAGES } from '../constants/stages.constans';
import { AvailableStepGuard } from './available-step.guard';
import { MenuService } from '../services/menu.service';

@Injectable({
  providedIn: 'root'
})
export class IdeasAvailable extends AvailableStepGuard {

  step = STAGES.IDEAS;

}
