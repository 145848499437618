import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

  constructor(
    private http: HttpClient
  ) { }

  downloadFile(file) {
    const base64str = file.fileBase64;
    const fileName = file.name || file.fileName;

    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: 'application/pdf' });
    importedSaveAs(blob, fileName);
  }

  get(postitFileId) {
    const url = `${environment.apiUrl}/PostitFile/${postitFileId}`;
    return this.http.get(url);
  }

}
