import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Stage } from '../../../pages/home/home.models';
import { GeneratePdfService } from '../../services/generate-pdf.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ErrorPdfComponent } from '../error-pdf/error-pdf.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PracticalWorkService } from '../../services/practical-work.service';
import { ListStudent } from '../../../pages/list-student/list-student.models';
import { StudentListService } from '../../services/student-list.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() showItems = true;
  @Input() moreInfo = null;
  @Input() title = null;
  @Input() isHome = false;
  @Input() displayMoreInfo = 'none';
  @Output() close = new EventEmitter();
  counter = 0;

  generatePdf = false;
  loadingMenu = true;
  isTeacher = false;
  student: ListStudent;

  constructor(
    public menuService: MenuService,
    private generatePdfService: GeneratePdfService,
    private modalService: NgbModal,
    private practicalWorkService: PracticalWorkService,
    private studentListService: StudentListService
  ) { }

  ngOnInit() {
    this.isTeacher = this.practicalWorkService.isTeacher();
    this.student = this.studentListService.student;
  }

  closeModal() {
    this.close.emit();
  }

  getPdf() {
    if (this.generatePdf) {
      return;
    }
    this.generatePdf = true;
    this.countUp(100, 180000);
    this.generatePdfService.get().subscribe(data => {
      this.counter = 99;
      setTimeout(() => {
        importedSaveAs(data, 'PdfFinal');
        this.generatePdf = false;
        this.counter = 0;
      }, 1500);
    }, () => {
      this.counter = 0;
      this.generatePdf = false;
      const modalRef = this.modalService
        .open(ErrorPdfComponent, { size: 'lg', backdrop: 'static', windowClass: 'postit-delete animated bounceInUp' });
    });
  }

  private countUp(max, time) {
    const step = time / max;
    const fn = () => {
      this.counter++;
      if (this.counter <= max) {
        window.setTimeout(fn, step);
      }
    };
    fn();
  }

  ngOnDestroy(): void {
    this.counter = 0;
  }

}
