export const STAGES = {
  BRAINSTORMING: 1,
  IDEAS: 2,
  CANVAS: 3,
  CIRCLE: 4,
  INTERVIEW: 5,
  CIRCLE_FINAL: 6,
  CANVAS_FINAL: 7,
  CASHFLOW: 8,
  PDF: 9,
};
