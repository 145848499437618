import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PostitService } from '../postit/postit.service';

@Component({
  selector: 'app-change-color',
  templateUrl: './change-color.component.html',
  styleUrls: ['./change-color.component.scss']
})
export class ChangeColorComponent implements OnInit {
  colors = [
    {
      className: 'yellow',
      colorCode: '#fff475',
      isActive: false
    },
    {
      className: 'orange',
      colorCode: '#f8bc32',
      isActive: false
    },
    {
      className: 'red',
      colorCode: '#f18b81',
      isActive: false
    },
    {
      className: 'pink',
      colorCode: '#f7cee8',
      isActive: false
    },
    {
      className: 'magenta',
      colorCode: '#d7aefb',
      isActive: false
    },
    {
      className: 'blue',
      colorCode: '#aecbfa',
      isActive: false
    },
    {
      className: 'cyan',
      colorCode: '#cbf0f8',
      isActive: false
    },
    {
      className: 'green',
      colorCode: '#caf690',
      isActive: false
    },
  ];

  @Input() postit: any;
  @Output() mouseOutEvent = new EventEmitter();

  constructor(
    private postitService: PostitService
  ) { }

  ngOnInit() {
    setTimeout(() => this.setActiveColor(), 10);
  }

  private setActiveColor() {
    this.resetIsActiveValues();
    this.colors.map(color => {
      if ( this.postit.backgroundColor === color.colorCode ) {
        color.isActive = true;
      }
    });
  }

  private resetIsActiveValues() {
    this.colors.map(color => color.isActive = false);
  }

  changeColor($event, postit, backgroundColor) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    postit.backgroundColor = backgroundColor;
    this.setActiveColor();

    this.postitService.update(postit)
      .subscribe();
  }

  mouseOut() {
    this.mouseOutEvent.emit('mouseOut');
  }


}
